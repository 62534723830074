import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import IdentifyingInformation from './pages/IdentifyingInformation';
import ProblemIdentification from './pages/ProblemIdentification';
import SolutionIdentification from './pages/SolutionIdentification';
import IPIncubation from './pages/IPIncubation';
import MarketCompetitors from './pages/MarketCompetitors';
import FoundersBackground from './pages/FoundersBackground';
import BusinessModel from './pages/BusinessModel';
import FundsRaised from './pages/FundsRaised';
import BddClarification from './BddClarification';
import Zoomlist from '../../jury-module/zoom-page/Zoomlist';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const ScstBddVerificationLayout = ({ idNumber, path, viewStartupDetails, startupValue }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue1('');
        setValue(newValue);
    };
    const [value1, setValue1] = useState();
    const handleChange1 = (event, newValue) => {
        setValue('');
        setValue1(newValue);
    };
    return (
        <div className='overallTab'>
            <Tabs
                className='verticalTabCSS'
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab className='verticalLable' label="Identifying Information" {...a11yProps(0)} />
                <Tab className='verticalLable' label="Problem Identification" {...a11yProps(1)} />
                <Tab className='verticalLable' label="Solution Identification" {...a11yProps(2)} />
                <Tab className='verticalLable' label="IP & Incubation" {...a11yProps(3)} />
                <Tab className='verticalLable' label="Market & Competitors" {...a11yProps(4)} />

            </Tabs>
            <Tabs
                className='verticalTabCSS'
                orientation="horizontal"
                variant="scrollable"
                value={value1}
                onChange={handleChange1}
                aria-label="Vertical tabs example"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab className='verticalLable' label="Founders Background" {...a11yProps(0)} />
                <Tab className='verticalLable' label="Business Model" {...a11yProps(1)} />
                <Tab className='verticalLable' label="Funds Raised" {...a11yProps(2)} />
                {
                    path === 'scstbdd' && (
                        <Tab className='verticalLable'
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Clarification
                                    {startupValue.bddClarificationRequested === true ? (
                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                    ) : (<></>)}
                                </div>
                            }
                             {...a11yProps(3)} />
                    )
                }
                {/* {
                    path === 'scstbdd' && (
                        <Tab className='verticalLable' label="Schedule Meeting" {...a11yProps(4)} />
                    )
                } */}


            </Tabs>
            <Box sx={{ p: 3 }}>
                <TabPanel value={value} index={0}><IdentifyingInformation idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value} index={1}><ProblemIdentification idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value} index={2}><SolutionIdentification idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value} index={3}><IPIncubation idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value} index={4}><MarketCompetitors idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value1} index={0}><FoundersBackground idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value1} index={1}><BusinessModel idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value1} index={2}><FundsRaised idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value1} index={3}><BddClarification idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} /></TabPanel>
                {/* <TabPanel value={value1} index={4}><Zoomlist startupId={idNumber} /></TabPanel> */}
            </Box>
        </div>
    )
}

export default ScstBddVerificationLayout