import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import TablePagination from "@mui/material/TablePagination";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import DataContext from "../../context/DataContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";
import Bulkupload from "../admin-module/Bulkupload";
import downloadExcel from "../../hooks/downloadExcel";
import Jurysave from "../partner-module/jury-details/Jurysave";
import { Dialog } from "@mui/material";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Scstjurylist = ({ path }) => {
  const {
    addRegister,
    modalstyle,
    setPageLoading,
    setUserId,
    tableIndexValue
  } = useContext(DataContext);
  const [partnerListData, setPartnerListData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInputValue, setSearchInputValue] = useState("");
  const roleName = localStorage.getItem("role");

  useDidMountEffect(() => {
    userListItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue, page, rowsPerPage,]);

  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
  };
  const userListItem = () => {
    setPageLoading(true)
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
    };
    apiService("investor/jury/list", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseModelList) {
          setPartnerListData(res.data.responseModelList);
          setCount(res.data.count)
        } else {
          setPartnerListData([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [loading, setLoading] = useState(false);
  const exportExcel = (event, yes) => {
    setLoading(true);
    // apiService('export/users?roleid=3&download=yes&active=1', '' , 'download').then((res) => {
    //   setLoading(false);
    //   if (res) {
    //     if (res.data) {
    //       downloadExcel(res.data, 'jury_')
    //     }
    //   }
    // })
    var req = {
      download: "yes",
      active: 1,
    };

    apiService("investor/jury/list", req, "downloadpost").then((res) => {
      setLoading(false);
      if (res) {
        if (res.data) {
          downloadExcel(res.data, "jury_");
        }
      }
    });
  };
  const [openBulk, setOpenBulk] = useState(false);
  const handleBulkOpen = () => setOpenBulk(true);
  const handleBulkClose = () => setOpenBulk(false);
  const [open, setOpen] = useState(false)
  const editValue = (id) => {
    setUserId(id)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    userListItem()
  }
  return (
    <section className="scsthub-div form_div chart">
      <div className="table_tag">
        <div className="table_header_tag d-flex">
          <SearchinputDiv
            sx={{ display: { xs: "none", sm: "block" } }}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by Name"
              type="search"
              inputProps={{ "aria-label": "Search by Name" }}
            />
          </SearchinputDiv>
          {roleName !== "SCSTHUB" ? (
            <>
              <Button
                className="ml-auto"
                variant="outlined"
                onClick={addRegister}
              >
                + Add Evaluator
              </Button>
              {/* <LoadingButton
                    className="ml-3"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CloudDownloadIcon />}
                    variant="outlined"
                    onClick={() => exportExcel()}
                  >
                    <span>Export JURY</span>
                  </LoadingButton> */}
            </>
          ) : (
            <></>
          )}
          {/* <Button className='ml-auto' variant="outlined" onClick={addRegister}>+ Add Jury</Button>
              <LoadingButton className='ml-3'
                loading={loading}
                loadingPosition="start"
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={() => exportExcel()}
              >
                <span>Export JURY</span>
              </LoadingButton> */}
          {/* <Button className='ml-3' startIcon={<CloudUploadIcon />} variant="outlined" onClick={handleBulkOpen}>JURY IMPORT</Button> */}
        </div>
        {partnerListData.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>LinkedIn</TableCell>
                  <TableCell>Expertise</TableCell>
                  {/* <TableCell align='center'>Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerListData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {
                        tableIndexValue(rowsPerPage, page, index)
                      }
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      {
                        row.linkedIn ? (
                          <Link to={`${row.linkedIn}`} target="_blank">
                            <LinkedInIcon className="linkedinicon" />
                          </Link>
                        ) : (`-`)
                      }

                    </TableCell>
                    <TableCell>{row.expertise}</TableCell>
                    {/* <TableCell align='center'>
                      <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => editValue(row.id)} />
                      <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deleteUser(row.id)} />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <caption>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: 'left' }}
                />
              </caption>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <p>Evaluator list is empty</p>
          </div>
        )}
      </div>
      <Dialog
        maxWidth={`lg`}
        open={open}       
      >
        <Box className="form_tag p-4">
          <Jurysave handleClose={handleClose} path={path} name={'Evaluator'} />
        </Box>
      </Dialog>
      <Modal
        open={openBulk}
        onClose={handleBulkClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <Bulkupload
            handleBulkClose={handleBulkClose}
            title={"Jury"}
            api={"jury"}
            roleid={3}
          />
        </Box>
      </Modal>
    </section>
  );
};

export default Scstjurylist;
