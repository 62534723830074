import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types';
// import BddClarification from './BddClarification';
import BDDDeepDive from './BDDDeepDive';
import BDDDeepDiveClarification from './BDDDeepDiveClarification';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const BDDDeepDiveLayout = ({ idNumber, path, viewStartupDetails, startupValue }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='overallTab'>
            {path === 'scstbdd' && (
                <Tabs
                    className='verticalTabCSS'
                    orientation="horizontal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tab className='verticalLable' label="Documents" {...a11yProps(0)} />
                    <Tab className='verticalLable'
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Clarification
                                {startupValue.bddDDClarificationRequested === true ? (
                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                ) : (<></>)}
                            </div>
                        }
                        {...a11yProps(1)} />

                </Tabs>
            )}
            <Box sx={{ p: 3 }}>
                <TabPanel value={value} index={0}><BDDDeepDive idNumber={idNumber} path={path} startupValue={startupValue} viewStartupDetails={viewStartupDetails}/></TabPanel>
                <TabPanel value={value} index={1}><BDDDeepDiveClarification idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} /></TabPanel>

            </Box>
        </div>
    )
}

export default BDDDeepDiveLayout