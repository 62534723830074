import React, { useState } from 'react'
import Viewimage from '../../../api/Viewimage';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

const ViewAllReports = ({ startupValue }) => {
    const [openReport, setOpenReport] = useState(false);
    const handleClickOpen = () => {
        setOpenReport(true)
    }
    const handleClickClose = () => {
        setOpenReport(false)
    }
    const [openReportImage, setOpenReportImage] = useState(false);
    const [viewReportImage, setViewReportImage] = useState('')
    const handleClickOpenImage = (value) => {
        setViewReportImage(value)
        setOpenReportImage(true);
    };
    const handleCloseImage = () => {
        setOpenReportImage(false);
    };
    return (
        <>
            <button className="viewdetails-sur mb-3" onClick={() => handleClickOpen()}>
                View Reports
            </button>
            <Dialog
                open={openReport}
                onClose={handleClickClose}
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="responsive-dialog-title">
                    All Report
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        // alignItems="flex-start"
                        justifyContent={'center'}
                        spacing={3}
                    >
                        {
                            startupValue.bddFirstConnectReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.bddFirstConnectReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        BDD First Connect Report
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            startupValue.bddDeepDiveReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.bddDeepDiveReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        BDD Deep Dive Report
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            startupValue.icReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.icReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        IC Report
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            startupValue.pscReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.pscReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        PSC Report
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            startupValue.lddReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.lddReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        LDD Report
                                    </div>
                                </Grid>
                            )
                        }
                        {
                            startupValue.fddReportDoc && (
                                <Grid item sm={12} md={3} lg={3}>
                                    <div className='report_view_div' onClick={() => handleClickOpenImage(startupValue.fddReportDoc)}>
                                        <img src='/images/pdf.png' className='pdf_img' alt='' />
                                        FDD Report
                                    </div>
                                </Grid>
                            )
                        }
                    </Grid>
                    <section className='report_view_section'>

                    </section>
                </DialogContent>
                <div className='text-center p-3'>
                    <Button variant="outlined" onClick={handleClickClose}>
                        Close
                    </Button>
                </div>
            </Dialog>
            <Viewimage viewImage={viewReportImage} open={openReportImage} handleClose={handleCloseImage} />
        </>
    )
}

export default ViewAllReports