import { Button, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import SendIcon from '@mui/icons-material/Send';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import DataContext from '../../../../context/DataContext';
import Viewimage from '../../../../api/Viewimage';
import Scsthubavpremarks from '../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import ClarificationAll from '../../scstBdd-details/ClarificationAll';

const IdentifyingInformation = ({ idNumber, path, startupValue,viewStartupDetails }) => {
    const { setPageLoading, getBDDStatus } = useContext(DataContext)
    const [formData, setFormData] = useState();
    const { handleSubmit, control, setValue, getValues, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const {
        fields: identifyingInformationFields,
    } = useFieldArray({
        control,
        name: "identifyingInformation"

    });
    useDidMountEffect(() => {
        getApi()
    }, [])
    const getApi = () => {
        setPageLoading(true)
        var req = {
            "startupId": idNumber,
            "category": 'Identifying Information',
            "round": 'firstconnect'
        }
        apiService(`partner/startup/clarification/list`, req, 'post').then((result) => {
            setPageLoading(false)
            if (result && result.data) {
                result.data.identifyingInformation.forEach(element => {
                    if (element.status === 3) {
                       
                        element.status = 2
                    }
                });
                setFormData(result.data)
            }
        }).catch((err) => {

        });
    }
    const handelVerifiedStatus = (value, i) => {
        console.log(value);
        setValue(`identifyingInformation.${i}.status`, Number(value))
        var getData = getValues(`identifyingInformation.${i}`)
        if (value === '1') {
            var req = {
                "bcId": getData.id,
                "startupId": idNumber,
                "status": 1,
                "round": "firstconnect"
            }
            apiService(`partner/startups/clarification/status/save`, req, 'post').then((result) => {
                if (result && result.data && result.data.responseStatus === 'Success') {
                    notifyService("success", "Success", "Saved successfully !!");
                    viewStartupDetails()
getApi()
                }
            }).catch((err) => {

            });
        }
        setFormData(getValues())

    }
    const handleRequestValue = (value, i) => {
        setValue(`identifyingInformation.${i}.clarification`, value)
        clearErrors(`identifyingInformation.${i}.clarification`)
    }
    const onSubmit = (data) => { }
    const handleRequestSubmit = (i) => {
        var getData = getValues(`identifyingInformation.${i}`)
        if (getData.clarification !== '') {
            var req = {
                "bcId": getData.id,
                "startupId": idNumber,
                "clarification": getData.clarification,
                "status": 2,
                "round": "firstconnect"
            }
            apiService(`partner/startup/clarification/save`, req, 'post').then((result) => {
                if (result && result.data && result.data.responseStatus === 'Success') {
                    notifyService("success", "Success", "Saved successfully !!");
                    viewStartupDetails()
getApi()
                }
            }).catch((err) => {

            });
        } else {
            setError(`identifyingInformation.${i}.clarification`, { message: "Request Clarification is required" })
        }

    }
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <div className="table_main_div">
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    {(identifyingInformationFields && identifyingInformationFields.length) ? (
                        <>
                            {
                                identifyingInformationFields.map((data, index) => (
                                    <div className='mb-3'>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <p className='mb-0'>{index + 1}. {data.attribute}</p>
                                            </Grid>
                                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                                {data.value && <div className='ans-div'>{data.value}</div>}
                                                {data.fileUrl && <div className='my-2'>View Document : <span className='view-doc' onClick={() => handleClickOpen(data.fileUrl)}>View</span></div>}
                                            </Grid>
                                            {
                                                (path === 'scstbdd' &&  startupValue.status < 6) ? (
                                                    <>
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <div>
                                                                <FormControl>
                                                                    <Controller name={`identifyingInformation.${index}.status`} control={control} defaultValue="" rules={{ required: false }}
                                                                        render={({ field }) =>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                defaultValue="Innovation"
                                                                                name="radio-buttons-group"
                                                                                {...field}
                                                                                onChange={(e) => handelVerifiedStatus(e.target.value, index)}
                                                                            >
                                                                                <span className='d-flex'>
                                                                                    <FormControlLabel disabled={(getValues(`identifyingInformation.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" />
                                                                                    <FormControlLabel disabled={(getValues(`identifyingInformation.${index}.status`) === 2 && data.clarification) ? true : false} value={2} control={<Radio />} label="More clarification" />
                                                                                </span>
                                                                            </RadioGroup>} />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.identifyingInformation?.[index]?.status && errors.identifyingInformation?.[index]?.status.message}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            {
                                                                getValues(`identifyingInformation.${index}.status`) === 2 && (
                                                                    <div className="form-group">
                                                                        <Controller
                                                                            name={`identifyingInformation.${index}.clarification`}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            rules={{ required: "Request Clarification is required" }}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="Request Clarification"
                                                                                    placeholder="Enter Message"
                                                                                    fullWidth
                                                                                    multiline
                                                                                    rows={3}
                                                                                    onInput={(e) => handleRequestValue(e.target.value, index)}
                                                                                    inputProps={{ readOnly: (getValues(`identifyingInformation.${index}.status`) === 2 && data.clarification) ? true : false }}
                                                                                    {...field}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <Button disabled={(getValues(`identifyingInformation.${index}.status`) === 2 && data.clarification) ? true : false} onClick={() => handleRequestSubmit(index)}><SendIcon /></Button>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <FormHelperText className="text-danger">
                                                                            {errors.identifyingInformation?.[index]?.clarification && errors.identifyingInformation?.[index]?.clarification.message}
                                                                        </FormHelperText>
                                                                    </div>
                                                                )
                                                            }
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <p className='mb-1'>Status : <b>{getBDDStatus(data.status)}</b></p>
                                                            {data.clarification && <p className='mb-1'>Clarification :  {data.clarificationAll ? <ClarificationAll remarks={data.clarificationAll} lblName="Clarifications" btnName="View" /> : <Scsthubavpremarks remarks={data.clarification} lblName="View" />} </p>}
                                                            {data.response && <p className='mb-1'>Startup Response : <Scsthubavpremarks remarks={data.response} lblName="View" /></p>}
                                                            {data.documentName && <p className='mb-1'>Startup Attached Document :  <Button onClick={() => handleClickOpen(data.documentName)} className='text-cap'>View</Button></p>}
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                        </Grid>
                                        <Divider />
                                    </div>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            <div className='nodata_tag'>
                                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                <h4>Identifying Information details is empty</h4>
                            </div>
                        </>
                    )
                    }
                </form>
            </div>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </div >
    )
}

export default IdentifyingInformation