import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../../api/apiService';
import { FormHelperText, TextField } from '@mui/material';
import notifyService from '../../../../../api/notifySerivce';
import DataContext from '../../../../../context/DataContext';
const Resubmit = ({ id, idNumber, historyList, name, viewStartupDetails }) => {
    const { setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, formState: { errors } } = useForm({});
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const onSubmit = (data) => {
        setPageLoading(true)
        var getform = data
        let url
        if (name === 'bdd' || name === 'ldd' || name === 'fdd') {
            getform.startupId = idNumber
            getform.bcId = id
            getform.status = 2
            getform.clarification = data.comments
            url = 'partner/startup/clarification/save'
        } else {
            getform.id = id
            getform.status = 0
            url = 'partner/startups/additionalinfo/resubmit'
        }
        apiService(url, getform, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Saved Successfully") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    historyList()
                    viewStartupDetails()
                    handleClose()
                }
            }
        })
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <button className='resubmit_btn' onClick={handleClickOpen}>Request More Details</button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Comments is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                variant='outlined'
                                                label="Enter Your Additional Comment"
                                                placeholder='Enter Your Additional Comment'
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center p-3'>
                            <Button className='mr-3' onClick={handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Resubmit
                            </Button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default Resubmit