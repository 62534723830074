import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Applicationscst from './Applicationscst';
import Fundingscstview from './Fundingscstview';
import Founderscstview from './Founderscstview';
import Moreinfosave from '../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave';
import IncubatorAccelerationScst from './IncubatorAccelerationScst';
import { Grid } from '@mui/material';
import ScstScoreValidation from './ScstScoreValidation';
import Scsthubavpevaluation from '../scsthubavp-module/scsthubavp-details/Scsthubavpevaluation';
import ScstBddVerificationLayout from '../scstBdd-page/scst-verification-details/ScstBddVerificationLayout';
import BDDDeepDive from './bdd-dee-dive/BDDDeepDive';
import BDDDeepDiveLayout from './bdd-dee-dive/BDDDeepDiveLayout';
import LDDLayout from './ldd-stage/LDDLayout';
import FDDLayout from './fdd-stage/FDDLayout';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Scsttab = ({ startupGetData, startupValue, idNumber, viewStartupDetails, listData, path, partnerOrInvestorId }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [valueTab, setValueTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    return (
        <div className="setHeg">
            {
                path === 'scstbdd' ? (
                    <Grid container>
                        <Grid item xs={2}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={valueTab}
                                onChange={handleChangeTab}
                                aria-label="Vertical tabs example"
                            >
                                <Tab
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Profile
                                            {startupValue.additionalInfoRequested === true ? (
                                                <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                            ) : (<></>)}
                                        </div>
                                    }
                                    {...a11yProps(0)} className='side-tab' />
                                {
                                    (startupValue.status >= 3) && (
                                        <Tab label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                BDD First Connect
                                                {startupValue.bddClarificationRequested === true ? (
                                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                ) : (<></>)}
                                            </div>
                                        }
                                            {...a11yProps(1)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 8) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    BDD deep dive
                                                    {startupValue.bddDDClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            } {...a11yProps(2)} className='side-tab' />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        <Grid item xs={10}>
                            <TabPanel value={valueTab} index={0}>
                                <div className='overallTab'>
                                    {/* <Box> */}
                                    <Tabs
                                        className='verticalTabCSS'
                                        orientation="horizontal"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                                        <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                                        <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                                        <Tab className='verticalLable' label="Incubation & Accelaration" {...a11yProps(3)} />
                                        <Tab className='verticalLable'
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Additional Info
                                                    {startupValue.additionalInfoRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(4)}
                                        />
                                    </Tabs>
                                    <Box sx={{ p: 3 }}>
                                        <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                                        <TabPanel value={value} index={3}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} idNumber={idNumber} /></TabPanel>
                                        <TabPanel value={value} index={4}><Moreinfosave idNumber={idNumber} detail={startupValue} viewStartupDetails={viewStartupDetails} path={path} /></TabPanel>
                                    </Box>
                                </div>
                            </TabPanel>
                            <TabPanel value={valueTab} index={1}>
                                <ScstBddVerificationLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                            </TabPanel>
                            <TabPanel value={valueTab} index={2}>
                                <BDDDeepDiveLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={2}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={valueTab}
                                onChange={handleChangeTab}
                                aria-label="Vertical tabs example"
                            >
                                <Tab
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Profile
                                            {startupValue.additionalInfoRequested === true ? (
                                                <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                            ) : (<></>)}
                                        </div>
                                    }
                                    {...a11yProps(0)} className='side-tab' />
                                {
                                    (startupValue.status >= 0) && (
                                        <Tab label="Validation Phase" {...a11yProps(1)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 1) && (
                                        <Tab label="Evaluation Phase" {...a11yProps(2)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 3) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    BDD First Connect
                                                    {startupValue.bddClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }

                                            {...a11yProps(3)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 8) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    BDD deep dive
                                                    {startupValue.bddDDClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(4)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 17) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    LDD
                                                    {startupValue.lddClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(5)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupValue.status >= 21) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    FDD
                                                    {startupValue.fddClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(6)} className='side-tab' />
                                    )
                                }
                                {/* <Tab label="FDD" {...a11yProps(5)} className='side-tab' /> */}
                            </Tabs>
                        </Grid>
                        <Grid item xs={10}>
                            <TabPanel value={valueTab} index={0}>
                                <div className='overallTab'>
                                    {/* <Box> */}
                                    <Tabs
                                        className='verticalTabCSS'
                                        orientation="horizontal"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                                        <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                                        <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                                        <Tab className='verticalLable' label="Incubation & Accelaration" {...a11yProps(3)} />
                                        <Tab className='verticalLable'
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Additional Info
                                                    {startupValue.additionalInfoRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(4)}
                                        />
                                    </Tabs>
                                    <Box sx={{ p: 3 }}>
                                        <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                                        <TabPanel value={value} index={3}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} idNumber={idNumber} /></TabPanel>
                                        <TabPanel value={value} index={4}><Moreinfosave idNumber={idNumber} detail={startupValue} viewStartupDetails={viewStartupDetails} path={path} /></TabPanel>
                                    </Box>
                                </div>
                            </TabPanel>
                            <TabPanel value={valueTab} index={1}>
                                <ScstScoreValidation idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} viewStartupDetails={viewStartupDetails} detail={startupValue} />
                            </TabPanel>
                            <TabPanel value={valueTab} index={2}>
                                <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                                    <Scsthubavpevaluation listData={listData} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={valueTab} index={3}>
                                <ScstBddVerificationLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                            </TabPanel>
                            {
                                (startupValue.status >= 8) && (
                                    <TabPanel value={valueTab} index={4}>
                                        <BDDDeepDiveLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                                    </TabPanel>
                                )
                            }
                            {
                                (startupValue.status >= 17) && (
                                    <TabPanel value={valueTab} index={5}>
                                        <LDDLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                                    </TabPanel>
                                )
                            }
                            {
                                (startupValue.status >= 21) && (
                                    <TabPanel value={valueTab} index={6}>
                                        <FDDLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                                    </TabPanel>
                                )
                            }
                        </Grid>
                    </Grid>
                )

            }

        </div>
    )
}

export default Scsttab


