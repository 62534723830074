import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import SaveIcon from "@mui/icons-material/Save";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import axios from 'axios';
import apiService from '../../../api/apiService';
import cryptoJS from '../../../hooks/cryptoJS';
const today = dayjs();
const tomorrow = dayjs().add(0, 'day');
const todayStartOfTheDay = today.startOf('day');
const Zoomscheduler = ({ zoomId, handleCloseModal, token, startupId }) => {
    const { modalstyle, setPageLoading } = useContext(DataContext)
    const tenant = localStorage.getItem("jwttoken")
    let zoomTokenlet = localStorage.getItem('zoomToken')
    let encryToken
    if (zoomTokenlet != null) {
        encryToken = cryptoJS(zoomTokenlet)
    }
    const [formData, setformData] = useState({})
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({ values: formData });
    const [openLbl, setOpenLbl] = useState('Create');
    let durationListValue = [
        15, 30, 45, 60, 90, 180
    ]
    useDidMountEffect(() => {
        if (zoomId !== 0) {
            setOpenLbl('Update')
            getApi()
        } else {
            setOpenLbl('Create')
        }
    }, [])
    const getApi = () => {
        setPageLoading(true)
        apiService(`get/meeting?meetingId=${zoomId}&key=${token}`, '', 'zoom_get').then((result) => {
            setPageLoading(false)
            if (result && result.data) {
                var response = result.data
                if (response.start_time) {
                    const date = '2024-07-01T10:30:00Z';
                    const formattedDate = formatDateTime(date);
                    const parsedDate = dayjs(response.start_time);
                    response.start_time = parsedDate
                    setDate(parsedDate)
                    setValue('start_time', parsedDate)
                    debugger
                    // const parsedDate = dayjs(response.start_time);
                    // const dates = dayjs(response.start_time, parsedDate.format('DD/MM/YYYY HH:mm'))
                    // response.start_time = dates
                    // setDate(dates)
                    // setValue('start_time', dates)
                    clearErrors('start_time');
                }
                if (response.duration) {
                    setMinValue(response.duration)
                }
                setformData(response)

            }
        }).catch((err) => {

        });
    }
    const formatDateTime = (dateString) => {
        return dayjs(dateString).format('DD/MM/YYYY HH:mm');
    };
    const [date, setDate] = useState(null)
    const [datePart, setdatePart] = useState('')
    const [timePart, settimePart] = useState('')
    const handleDateFormat = (newValue) => {
        // 'YYYY-MM-DDTHH:mm:ssZ'
        const datePart = newValue.format('YYYY-MM-DD');
        const timePart = newValue.format('HH:mm');
        setdatePart(datePart)
        settimePart(timePart)
        // var date=dayjs(newValue).add(1, 'day')
        debugger
        setDate(newValue)
        setValue('start_time', newValue)
        clearErrors('start_time');
    }
    const [minValue, setMinValue] = React.useState('');
    const handleChangeMin = (event) => {
        setMinValue(event.target.value);
        setValue('duration', event.target.value)
        clearErrors('duration')
    };

    const onSubmit = (data) => {
        setPageLoading(true)
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // data.agenda = "My Meeting"
        // data.password = false
        // data.date = data.date.toISOString()
        // data.start_time = data.start_time.toISOString()
        // data.timezone = timeZone
        // data.schedule_for = "harish.u@amizhth.com"
        // data.type = 2
        // data.meetingId = zoomId;
        // start_time: data.start_time.toISOString(),
        // 2024-06-12T07:30:00.000Z
        var req = {
            pre_schedule: false,
            start_time: datePart + 'T' + timePart + ':00Z',
            timezone: data.timezone ? data.timezone : timeZone,
            type: 2,
            meetingId: zoomId,
            topic: data.topic,
            schedulefor: data.schedulefor,
            duration: data.duration,
            agenda: data.agenda
        }
        debugger

        console.log(data)
        let url
        if (openLbl === 'Create') {
            url = `create/meeting`
        } else {
            url = `update/meeting`
            debugger
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'token': tenant,
                'key': encryToken,
                'meetingId': (openLbl === 'Create') ? '' : zoomId,
                "startupid": startupId
            },
        };
        const URL = `${process.env.REACT_APP_API_URL}${url}`;
        axios.post(URL, req, config)
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    handleCloseModal()
                }
            }).catch((err) => {
                debugger
                setPageLoading(false)
                // notifyService('danger', 'Error', err.data.responseStatus)
            });
        // apiService(url, req , 'zoom_post').then((result) => {
        //     if (result && result.data) {
        //         handleCloseModal()
        //     }
        // }).catch((err) => {

        // });
        //  console.log(timeZone);
        // handleCloseModal()
    }
    return (
        <>

            <Box className="form_tag p-4">
                <div>
                    <h4 className="mb-4">
                        {
                            openLbl === 'Create' ? (<b>Schedule Your Meeting</b>) : (<b>Update Meeting</b>)
                        }

                    </h4>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {/* <div className='col-lg-6 col-12'>
                                <div className='form-group mt-2'>
                                    <Controller
                                        name={`agenda`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Agenda is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                label="Agenda *"
                                                placeholder="Enter agenda"
                                                {...field}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.agenda &&
                                            errors.agenda.message}
                                    </FormHelperText>
                                </div>
                            </div> */}
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mt-2'>
                                    <Controller
                                        name={`topic`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Topic is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                label="Topic *"
                                                placeholder="Enter Topic"
                                                {...field}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.topic &&
                                            errors.topic.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='form-group mt-1'>
                                    <Controller
                                        name={`start_time`}
                                        control={control}
                                        // defaultValue={date}
                                        rules={{ required: 'Date is required' }}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimePicker']} fullWidth>
                                                    <DateTimePicker label="Date and Time *" format="DD/MM/YYYY HH:mm"
                                                        minDate={tomorrow}
                                                        value={date}
                                                        {...field}
                                                        sx={{ width: "100%" }}
                                                        inputFormat="DD/MM/YYYY HH:mm"
                                                        onChange={(newValue) => handleDateFormat(newValue)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.start_time &&
                                            errors.start_time.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-12'>
                                    <div className='form-group mt-2 timepickercss'>
                                        <Controller name="start_time" control={control} defaultValue={time} rules={{ required: `Time is required` }}
                                            render={({ field }) =>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']} fullWidth>
                                                        <DemoItem>
                                                            <TimePicker label="Time *" {...field} sx={{ border: futureTimeSelected ? "1px solid red borderRadius:5px" : "0px solid #ccc" }} ampm={false} fullWidth defaultValue={todayStartOfTheDay} onChange={(newValue) => handleTimeFormat(newValue)} />
                                                            {futureTimeSelected && <p className='text-danger' style={{ fontSize: '12px' }}>Future time is not allowed</p>}
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>}
                                        />
                                        <FormHelperText className='text-danger'>{errors.start_time && errors.start_time.message}</FormHelperText>
                                    </div>
                                </div> */}
                            <div className='col-lg-6 col-12'>
                                {/* <InputLabel>Duration</InputLabel> */}
                                <div className='row'>
                                    {/* <div className='col-lg-6 col-12'>
                                            <FormControl fullWidth>
                                                <InputLabel>Hr</InputLabel>
                                                <Controller
                                                    name={`duration`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: 'Hr is required' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={hrValue}
                                                            onChange={handleChangeHr}
                                                        >
                                                            {
                                                                hr.map((list) => (
                                                                    <MenuItem value={list}>{list}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperText className='text-danger'>{errors.duration && errors.duration.message}</FormHelperText>

                                            </FormControl>
                                        </div> */}
                                    {/* <div className='col-lg-6 col-12'>
                                            <FormControl fullWidth>
                                                <InputLabel>Duration</InputLabel>
                                                <Controller
                                                    name={`duration`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: 'Min is required' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={minValue}
                                                            onChange={handleChangeMin}
                                                        >
                                                            {
                                                                min.map((list) => (
                                                                    <MenuItem value={list}>{list}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperText className='text-danger'>{errors.duration && errors.duration.message}</FormHelperText>

                                            </FormControl>
                                        </div> */}
                                </div>
                                <div className='form-group mt-2'>
                                    <FormControl fullWidth>
                                        <InputLabel>Duration (Minutes)</InputLabel>
                                        <Controller
                                            name={`duration`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Duration is required' }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={minValue}
                                                    onChange={handleChangeMin}
                                                >
                                                    {
                                                        durationListValue.map((list) => (
                                                            <MenuItem value={list}>{list}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            )}
                                        />
                                        <FormHelperText className='text-danger'>{errors.duration && errors.duration.message}</FormHelperText>

                                    </FormControl>
                                </div>

                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mt-2'>
                                    <Controller
                                        name={`agenda`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Agenda is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                id="filled-textarea"
                                                label="Agenda *"
                                                placeholder="Enter Agenda"
                                                multiline
                                                {...field}
                                                fullWidth
                                                rows={4}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.agenda && errors.agenda.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mt-2'>
                                    <Controller
                                        name={`schedulefor`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Attendees is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                label="Attendees *"
                                                placeholder="Enter Attendees"
                                                {...field}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <span className='agenda-msg'>(Use comma(,) to include multiple attendees. Ex(abc@test.com,def@test.com))</span>
                                    <FormHelperText className="text-danger">
                                        {errors.schedulefor &&
                                            errors.schedulefor.message}
                                    </FormHelperText>
                                </div>
                            </div>

                        </div>
                        <div className='p-3 text-center'>
                            <Button
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                sx={{
                                    margin: '3px', borderRadius: "25px", "&:hover": {
                                        backgroundColor: "green",
                                    }
                                }}
                            >{
                                    openLbl === 'Create' ?
                                        (<span>Schedule Meeting</span>) : (<span>Update Meeting</span>)
                                }
                            </Button>
                            <Button variant="contained" color="error" onClick={handleCloseModal} sx={{
                                margin: '3px', borderRadius: "25px", "&:hover": {
                                    backgroundColor: "#007aff",
                                }
                            }}>Cancel</Button>
                        </div>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default Zoomscheduler