import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Appicationview from './Appicationview';
import Fundingfinancialsview from './Fundingfinancialsview';
import Teaminfoview from './Teaminfoview';
import Productmarketfit from './Productmarketfit';
import Businessstrategy from './Businessstrategy';
import Tractionachievements from './Tractionachievements';
import Fundingutilisation from './Fundingutilisation';
import Documentsupload from './Documentsupload';
import Moreinfosave from './Moreinfosave';
import Incubationaccelarationview from './Incubationaccelarationview';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Applicationscst from '../../../../scst-page/Applicationscst';
import Fundingscstview from '../../../../scst-page/Fundingscstview';
import Founderscstview from '../../../../scst-page/Founderscstview';
import Zoomlist from '../../../../jury-module/zoom-page/Zoomlist';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import IncubatorAccelerationScst from '../../../../scst-page/IncubatorAccelerationScst';
import { Grid } from '@mui/material';
import ScstScoreValidation from '../../../../scst-page/ScstScoreValidation';
// import ScstBDD from '../../../../scst-page/ScstBDD';
import Scsthubavpevaluation from '../../../../scsthubavp-module/scsthubavp-details/Scsthubavpevaluation';
import ScstBddVerificationLayout from '../../../../scstBdd-page/scst-verification-details/ScstBddVerificationLayout';
// import Scsthubavpevaluation from '../../../../../../scsthubavp-details/Scsthubavpevaluation';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Associatedscsttab = ({ startupGetData, startupValue, idNumber, name, viewStartupDetails, listData, path,partnerOrInvestorId }) => {
    console.log(path);

    useDidMountEffect(() => {
        setValue(0)
    }, [idNumber])
    // const [value, setValue] = useState(0);

    // const handleChange = (event, newValue) => {
    //     // setValue1('');
    //     setValue(newValue);
    // };
    // const [value1, setValue1] = useState();

    // const handleChange1 = (event, newValue) => {
    //     setValue('');
    //     setValue1(newValue);

    // };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [valueTab, setValueTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    return (
        <div>
            <Grid container>
                <Grid item xs={2}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={valueTab}
                        onChange={handleChangeTab}
                        aria-label="Vertical tabs example"
                    // sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Profile" {...a11yProps(0)} className='side-tab' />
                        {
                            (
                                startupValue.status === 0 || startupValue.status === 1 ||
                                startupValue.status === 2 || startupValue.status === 3 ||
                                startupValue.status === 4
                            ) && (
                                <Tab label={path === 'jury/scst' ? "Evaluation Phase" : "Validation Phase"} {...a11yProps(1)} className='side-tab' />
                            )
                        }
                        {
                            (path !== 'jury/scst' && (startupValue.status === 1 || startupValue.status === 3 || startupValue.status === 4)) && (
                                <Tab label="Evaluation Phase" {...a11yProps(2)} className='side-tab' />
                            )
                        }
                        {
                            (path !== 'jury/scst' && startupValue.status === 3) && (
                                <Tab label="BDD" {...a11yProps(3)} className='side-tab' />
                            )
                        }
                        {/* <Tab label="LDD" {...a11yProps(4)} className='side-tab' /> */}
                        {/* <Tab label="FDD" {...a11yProps(5)} className='side-tab' /> */}
                    </Tabs>
                </Grid>
                <Grid item xs={10}>
                    <TabPanel value={valueTab} index={0}>
                        <div className='overallTab'>
                            <Box>
                                <Tabs
                                    className='verticalTabCSS'
                                    orientation="horizontal"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                                >
                                    <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                                    <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                                    <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                                    <Tab className='verticalLable' label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Additional Info
                                            {startupValue.additionalInfoRequested === true ? (
                                                <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                            ) : (<></>)}
                                        </div>
                                    } {...a11yProps(3)} />
                                    <Tab className='verticalLable' label="Incubation / Accelaration" {...a11yProps(4)} />
                                    {/* <Tab className='verticalLable' label="Schedule Meeting" {...a11yProps(5)} /> */}
                                </Tabs>
                                <Box sx={{ p: 3 }}>
                                    <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                                    <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                                    <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                                    <TabPanel value={value} index={3}><Moreinfosave idNumber={idNumber} detail={startupValue} viewStartupDetails={viewStartupDetails} /></TabPanel>
                                    <TabPanel value={value} index={4}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} /></TabPanel>
                                    {/* <TabPanel value={value} index={5}><Zoomlist startupId={idNumber} /></TabPanel> */}
                                </Box>
                            </Box>
                        </div>
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <ScstScoreValidation idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} viewStartupDetails={viewStartupDetails} detail={startupValue} />
                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                        <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                            <Scsthubavpevaluation listData={listData} />
                        </Box>
                    </TabPanel>
                    <TabPanel value={valueTab} index={3}>
                        <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                            <ScstBddVerificationLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} />
                        </Box>
                    </TabPanel>
                </Grid>
            </Grid>

        </div>
    )
}

export default Associatedscsttab