import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
const Scsthubavpremarks = ({ remarks,lblName }) => {
    const { modalstyle } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);  
    return (
        <>
            {
                remarks ? (
                    <Button onClick={() => setOpenModal(true)} className='text-cap'>{lblName}</Button>
                ) : (`-`)
            }
            <Modal
                open={openModal}
                onClose={() => setOpenModal(true)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag p-5">
                    <div className='text-center'>                        
                        <p>{remarks}</p>
                        <center>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Ok</Button>
                        </center>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Scsthubavpremarks