import React, { useContext, useState } from 'react'
import { Box, Button, Container, Drawer, Tab } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataContext from '../../context/DataContext';
import { Link, useParams } from 'react-router-dom';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Scsttab from './Scsttab';
import { Stack } from '@mui/material';
// import Validatetab from './Validatetab';
import Scoremodal from './Scoremodal'
// import Startuplistfilter from './Startuplistfilter';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Startuplistfilter from '../investor-module/associated-startups-page/associated-details-page/Startuplistfilter';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import Viewimage from '../../api/Viewimage';
import CommonViewPitchDeck from '../common-page/CommonViewPitchDeck';
import BDDSubmitReport from './BDDSubmitReport';
import ViewAllReports from './view-reports/ViewAllReports';
import notifyService from '../../api/notifySerivce';
import IcPscApprovel from './ic-psc-approvel/IcPscApprovel';
const ScstDetails = ({ path }) => {
    const { navigator, setPageLoading, getSCSTStartupStatus } = useContext(DataContext)
    const handleBack = () => {
        if (path === 'validation') {
            navigator(`/scst/startups/validation`)
        } else {
            navigator(`/${path}/startups/list`)
        }

    }
    const [startupGetData, setStartupGetData] = useState({})
    const { id } = useParams()
    const { startupId } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)
    useDidMountEffect(() => {
        // eslint-disable-next-line
        viewStartupDetails(idNumber, partnerOrInvestorId)
        listApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [startupValue, setStartupValue] = useState({})
    const viewStartupDetails = async () => {
        setPageLoading(true)
        setNavTabValue('1')
        setStartupGetData({})
        setStartupValue({})
        apiService(`partner/startups/get?id=${idNumber}`, '', 'get').then((res) => {

            if (res) {
                if (res.data) {
                    if (res.data.applicationForm) {
                        setStartupGetData(res.data.applicationForm)
                    }
                    setStartupValue(res.data)

                    // if (res.data.showRounds === true) {
                    //     roundPartnerList()
                    // }
                } else {
                    setStartupGetData({})
                }
            }
            setPageLoading(false)
        })
    }
    const [navTabValue, setNavTabValue] = useState('1');
    const handleNavTabChange = (event, newValue) => {
        setNavTabValue(newValue);
    };
    const [openList, setOpenList] = React.useState(false);
    const handleStartupList = () => {
        setOpenList(true);
    }

    const handleClose = (value) => {
        setOpenList(false);
    };

    const isRegistered = startupGetData.dpiitRegistered;

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        setViewImage(value);
        setOpen(true);
    };
    const handleClose1 = () => {
        setOpen(false);
    };
    const [listData, setListData] = useState({})
    const listApi = () => {
        setPageLoading(true)
        var req = {
            "partnerOrInvestorId": partnerOrInvestorId,
            "startupId": idNumber
        }
        apiService('partner/scst/admin/startup/get', req, 'post').then((result) => {

            if (result) {
                if (result.data.startupRounds) {
                    setListData(result.data.startupRounds[1])
                }
            }
            setPageLoading(false)
        }).catch((err) => {

        });
    }
    const [openReportImage, setOpenReportImage] = useState(false);
    const [viewReportImage, setViewReportImage] = useState('')
    const handleClickOpenImage = (value) => {
        setViewReportImage(value)
        setOpenReportImage(true);
    };
    const handleCloseImage = () => {
        setOpenReportImage(false);
    };
    const onStatusUpdate = (data) => {
        setPageLoading(true)
        var getform = {
            status: data,
            startupId: idNumber
        }
        apiService('partner/scst/status/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    viewStartupDetails(idNumber, partnerOrInvestorId)
                }
            }
        })
    };
    return (
        <div className='box-show'>
            <section className='bg-white'>
                <div className='row'>
                    {/* <div className='col-lg-2 p-0'>
                        <div className='box-line'>
                            <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                        </div>
                    </div> */}
                    <div className='col-lg-12 p-0'>
                        <div className='p-relative'>
                            <div className='p-3'>
                                <div className='dflex'>
                                    <div className='d-flex align-items-center'>
                                        <div><Button onClick={handleBack}><ArrowBackIcon /></Button></div>
                                        <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                                        <div className='content'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <h3 className='mr-3 mb-0'>{startupValue.startupName}</h3>
                                                <button className={`dpi-button ${isRegistered ? 'register-btn' : 'not-register-btn'}`}>
                                                    {isRegistered && <AiFillSafetyCertificate className="icon" />}
                                                    {isRegistered ? 'DPIIT Recognised' : 'DPIIT Not Registered'}
                                                </button>
                                                <button
                                                    className="viewdetails-surpitch"
                                                    onClick={() => {
                                                        handleClickOpen(startupGetData.pitchDeck);
                                                    }}
                                                >
                                                    View PitchDeck
                                                </button>
                                            </div>

                                            <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                            <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                            <span className='black-clr-sur ml-3'>Member Since : {startupValue.date}</span>
                                            <div className='mt-3'>Status : <span className='status_startup'>{getSCSTStartupStatus(startupValue.status)}</span></div>

                                        </div>
                                    </div>
                                    <div className='ml-auto mr-3 text-right'>
                                        {path === 'scstbdd' && (
                                            <>
                                                {
                                                    startupValue.bddSubmitButton === true && (
                                                        <BDDSubmitReport idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="BDD First Connect" status={6} />
                                                    )
                                                }
                                                {
                                                    startupValue.bddDeepDiveSubmitButton === true && (
                                                        <BDDSubmitReport idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="BDD Deep Dive" status={10} />
                                                    )
                                                }
                                            </>
                                        )}
                                        {path === 'scstldd' && (
                                            <>
                                                {
                                                    startupValue.lddSubmitButton === true && (
                                                        <BDDSubmitReport idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="LDD" status={18} />
                                                    )
                                                }
                                            </>
                                        )}
                                        {path === 'scstfdd' && (
                                            <>
                                                {
                                                    startupValue.fddSubmitButton === true && (
                                                        <BDDSubmitReport idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="FDD" status={22} />
                                                    )
                                                }
                                            </>
                                        )}
                                        {
                                            startupValue.additionalDocName && (
                                                <button className="viewdetails-sur mr-3" onClick={() => handleClickOpenImage(startupValue.additionalDocName)}>
                                                    View Report
                                                </button>
                                            )
                                        }
                                        {/* view reports */}
                                        <div className='mt-3'>
                                            {
                                                startupValue.bddDeepDiveReportDoc ? (
                                                    <ViewAllReports startupValue={startupValue} />
                                                ) : (
                                                    <>
                                                        {
                                                            startupValue.bddFirstConnectReportDoc && (
                                                                <button className="viewdetails-sur mr-3 mb-3" onClick={() => handleClickOpenImage(startupValue.bddFirstConnectReportDoc)}>
                                                                    BDD First Connect Report
                                                                </button>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        {(path === 'ic' && startupValue.status === 11) && (
                                            <>
                                                <div className='mt-3'>
                                                    <IcPscApprovel classNa={'viewdetails-Approve  mr-3'} btnName={'Approve for PSC'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="IC" status={13} />
                                                    <IcPscApprovel classNa={'viewdetails-Schemes'} btnName={'Recommended to Other Schemes'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="IC" status={14} />
                                                </div>
                                            </>
                                        )}
                                        {(path === 'psc' && startupValue.status === 13) && (
                                            <>
                                                <div className='mt-3'>
                                                    <IcPscApprovel classNa={'viewdetails-Approve  mr-3'} btnName={'Approve for LDD'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="PSC" status={16} />
                                                    <IcPscApprovel classNa={'viewdetails-Schemes'} btnName={'Recommended to Other Schemes'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} lbl="PSC" status={19} />
                                                </div>
                                            </>
                                        )}                                        
                                    </div>
                                </div>
                                <hr />
                                <Scsttab startupGetData={startupGetData} startupValue={startupValue} idNumber={idNumber} viewStartupDetails={viewStartupDetails} listData={listData} path={path} partnerOrInvestorId={partnerOrInvestorId} />
                            </div>

                            <Drawer anchor="left" open={openList} onClose={handleClose} className="scroe_module">

                                <Box sx={{ width: 250 }} role="presentation">
                                    <div className='box-line-sm'>
                                        <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                                    </div>
                                </Box>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </section>
            <CommonViewPitchDeck viewImage={viewImage} open={open} handleClose={handleClose1} />
            <Viewimage viewImage={viewReportImage} open={openReportImage} handleClose={handleCloseImage} />
        </div>
    )
}

export default ScstDetails
