import React, { useContext, useRef, useState } from "react";
import { Autocomplete, FormControl, FormLabel, InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
// import apiService from '../../../api/apiService';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import { MuiFileInput } from "mui-file-input";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import Container from "@mui/material/Container";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import Viewimage from "../../../api/Viewimage";
import CustomLink from "../../common-page/CustomLink";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import cryptoJS from "../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import axios from "axios";

const fileTypes = ["pdf"];
const fileTypesimg = ["jpg", "png", "jpeg"];
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const isWhitespace = (value) => {
  return /^\s*$/.test(value);
};
const Applicationscst = () => {
  const showAddInfo = localStorage.getItem("showInfo")
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const { validateEmail, validatePhoneNumber, handleBackapp, readyOnlyValue, setReadyOnlyValue, validatePANNumber, validateCin, validateDPIINumber, checkAlphanumericnotcap, checkAlphanumeric, navigator, VisuallyHiddenInput, setStartupProfileValue, setPageLoading } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
  const dpiitRegistered = watch("dpiitRegistered", false);
  const [checkMethod, setCheckMethod] = useState("save");
  const [certificateFile, setCertificateFile] = useState("save");
  const [udhayamFile, setUdhayamFile] = useState("save");
  const [partnershipDeedFile, setPartnershipDeedFile] = useState("save");
  const [moaFile, setMoaFile] = useState("save");



  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
    getCityApi();
    getStateApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyOnlyValue, setReadyOnlyValue]);
  const [entityTypeList, setEntityTypeList] = useState([]);
  const [startupStageData, setStartupStageData] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=startup_min`,
      "",
      "get"
    ).then((res) => {
      var val = res.data.dropDownValues;
      // setSectorData(val.sectors)
      setEntityTypeList(val.legalEntities);
      setStartupStageData(val.startupStages);
    });
  };
  // const [cityList, setCityList] = useState([
  //     'Madurai'
  // ])
  // const cityList = [
  //     'Madurai'
  // ]
  const [cityList, setCityList] = useState([]);
  const getCityApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setCityList(val.districts);
      }
    );
  };
  const [stateList, setStateList] = useState([]);
  const getStateApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=state`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setStateList(val.districts);
      }
    );
  };
  const handleSelectCity = (value) => {
    setValue("city", value);
    clearErrors("city");
  };
  // const stateList = ["Tamil Nadu"];
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/startupdetails/get`, "", "get").then((res) => {
      if (res) {
        let response = res.data;
        setPageLoading(false)
        if (response.id !== 0) {
          setFormData({});
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
            setReadyOnlyBDDValue(true)
          } else {
            if (showAddInfo === 'true') {
              setReadyOnlyValue(true);
            } else {
              setReadyOnlyValue(false);
            }
            setReadyOnlyBDDValue(false)
          }
          if (response.panNumber) {
            setPanTickIcon(1)
          }
          if (response.certificateNumber) {
            setCinTickIcon(1)
          }
          if (response.entityType) {
            setEntityTypeValue(response.entityType);
          }
          if (response.moaDoc) {
            setIsUploadedMoa(true)
            setMoaDocValue(response.moaDoc);
            setMoaDocName(response.moaDoc);
            setMoaDocURL(response.moaDocName);
            setMoaFile("update");
          }
          if (response.inCorpCertificate) {
            setIsUploadedCin(true)
            setInCorporationValue(response.inCorpCertificate);
            setInCorporationName(response.inCorpCertificate);
            setInCorporationURL(response.inCorpCertificateName);
            setCheckMethod("update");
          }
          if (response.udayamCertificate) {
            setIsUploaded(true)
            setValue("udayamCertificate", response.udayamCertificate);
            clearErrors("udayamCertificate");
            setUdayamCertificateValue(response.udayamCertificate);
            setUdayamCertificateName(response.udayamCertificate);
            setUdayamCertificateURL(response.udayamCertificateName);
            setUdhayamFile("update");
          }

          if (response.partnershipDeed) {
            setIsUploadedDeed(true)
            setPartnershipDeedValue(response.partnershipDeed);
            setPartnershipDeedName(response.partnershipDeed);
            setPartnershipDeedURL(response.partnershipDeedName);
            setPartnershipDeedFile("update");
          }

          if (response.dpiitCertificate) {
            setIsUploadedDpiit(true)
            setValue("dpiitCertificate", response.dpiitCertificate);
            clearErrors("dpiitCertificate");
            setDpiitCertificateValue(response.dpiitCertificate);
            setDpiitCertificateName(response.dpiitCertificate);
            setDpiitCertificateURL(response.dpiitCertificateName);
            setCertificateFile("update");
            ;
          }
          if (response.pincode) {
            setValuePincode(response.pincode)
          }
          if (response.uniqueDesc) {
            setUniqueDescValue(response.uniqueDesc)
          }
          if (response.briefStartupDescription) {
            setBriefStartupDescriptionValue(response.briefStartupDescription)
          }

          if (response.entityType) {
            setEntityTypeValue(response.entityType);
          }
          if (response.startupLogoName === undefined) {
            setLogoDisplayUrl("/images/upload-img.png");
            setStartupProfileValue(false);
          } else {
            setIsUploadedLogo(true)
            setLogoDisplayUrl(response.startupLogoName);
            setLogoDisplayValue(response.startupLogo);
            setLogoDisplayName(response.startupLogo);
            setStartupProfileValue(true);
          }
          setValue("startupLogo", response.startupLogo);
          setValue("startupLogoName", response.startupLogoName);
          setValue("inCorpCertificate", response.inCorpCertificate);
          setValue("inCorpCertificateName", response.inCorpCertificateName);
          response.country = response.country ? response.country : 'India'
          response.state = response.state ? response.state : 'Tamil Nadu'
          // setStateValue(response.state)
        } else {
          if (response.pincode === 0) {
            response.pincode = "";
          }
          response.country = 'India'
          response.state = 'Tamil Nadu'
          setFormData(response);
        }
      }
    });
  };
  // const [entityValue, setEntityValue] = useState();

  const [inCorporationValue, setInCorporationValue] = useState(null);
  const [inCorporationName, setInCorporationName] = useState(null);
  const [inCorporationURL, setInCorporationURL] = useState(null);

  const [moaDocValue, setMoaDocValue] = useState(null);
  const [moaDocName, setMoaDocName] = useState(null);
  const [moaDocURL, setMoaDocURL] = useState(null);

  const [udayamCertificateValue, setUdayamCertificateValue] = useState(null);
  const [udayamCertificateName, setUdayamCertificateName] = useState(null);
  const [udayamCertificateURL, setUdayamCertificateURL] = useState(null);

  const [partnershipDeedValue, setPartnershipDeedValue] = useState(null);
  const [partnershipDeedName, setPartnershipDeedName] = useState(null);
  const [partnershipDeedURL, setPartnershipDeedURL] = useState(null);


  const [dpiitCertificateValue, setDpiitCertificateValue] = useState(null);
  const [dpiitCertificateName, setDpiitCertificateName] = useState(null);
  const [dpiitCertificateURL, setDpiitCertificateURL] = useState(null);

  const [logoDisplayUrl, setLogoDisplayUrl] = useState("/images/upload-img.png");
  const [logoDisplayValue, setLogoDisplayValue] = useState(null);
  const [logoDisplayName, setLogoDisplayName] = useState(null);

  const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl, format) => {
    if (event !== null) {
      let logoselectedFile;
      if (event.target === undefined) {
        logoselectedFile = event;
      } else {
        logoselectedFile = event.target.files[0];
      }
      if (logoselectedFile) {
        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
        if (logoselectedFile.size > fileSizeLimit) {
          // File size exceeds limit, show error message
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return; // Exit function
        }
        var reader = new FileReader();
        var imagetype = logoselectedFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          (format === "file" &&
            (img_crt_type === "jpeg" ||
              img_crt_type === "jpg" ||
              img_crt_type === "png" ||
              img_crt_type === "pdf")) ||
          (format === "image" &&
            (img_crt_type === "jpeg" ||
              img_crt_type === "jpg" ||
              img_crt_type === "png"))
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          setName("");
          setUrl("");
          if (event.target === undefined) {
            setdata(event);
          } else {
            setdata(event.target.files[0]);
          }
          var fileValue = logoselectedFile;
          reader.readAsDataURL(logoselectedFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setName(img_name);
            clearErrors(filekey);
            clearErrors(fileName);

          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format."
          );
        }
      }
    } else {
      // Handle case when event is null (optional)
    }
  };

  const [entityTypeValue, setEntityTypeValue] = useState("");
  const handleSelectEntityType = (value) => {
    setEntityTypeValue(value);
    setValue("entityType", value);
    clearErrors("entityType");
  };

  // const [stateValue, setStateValue] = useState("");
  const handleSelectStateType = (value) => {
    // setStateValue(value);
    setValue("state", value);
    clearErrors("state");
    setValue("city", '');
  }

  const handleSelectStartupStage = (value) => {
    setValue("currentgrowthStage", value);
    clearErrors("currentgrowthStage");
  };
  const [panTickIcon, setPanTickIcon] = useState(0)
  const handlePanValidate = (value) => {
    var valid = validatePANNumber(value)
    if (valid === true) {
      apiService(`startup/pan/validate?pan=${cryptoJS(value)}`, '', 'get').then((result) => {
        if (result && result.data) {
          if (result.data === 'Success') {
            setPanTickIcon(1)
          } else {
            setPanTickIcon(2)
          }
        } else {
          setPanTickIcon(2)
        }
      }).catch((err) => {

      });
    } else {
      setPanTickIcon(0)
    }
  }
  const [cinTickIcon, setCinTickIcon] = useState(0)
  const handleCinValidate = (value) => {
    var valid = validateCin(value)
    if (valid === true) {
      apiService(`startup/cin/validate?cin=${cryptoJS(value)}`, '', 'get').then((result) => {
        if (result && result.data) {
          if (result.data === 'Success') {
            setCinTickIcon(1)
          } else if (result.data === 'Invalid') {
            setCinTickIcon(3)
          } else {
            setCinTickIcon(2)
          }
        } else {
          setCinTickIcon(2)
        }
      }).catch((err) => {

      });
    } else {
      setCinTickIcon(0)
    }

  }

  const onSubmit = (data) => {
    debugger
    if (readyOnlyBDDValue === false) {

      if (panTickIcon === 2) {
        return notifyService("danger", "Error", "Please check your PAN Number");
      }
      if (cinTickIcon === 2) {
        return notifyService("danger", "Error", "Please check your CIN Number");
      }
      // if (logoDisplayValue !== null) {
      // setLoading(true);
      var getform = data;
      if(getform.certificateNumber){
        getform.certificateNumber = getform.certificateNumber.toUpperCase()
      }
     
      // getform.incorporatedDate = data.incorporatedDate.format("DD/MM/YYYY")
      setPageLoading(true)
      apiService("startup/startupdetails/save", getform, "post").then(
        (res) => {
          setPageLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              navigator("/startup/funding-financials/scst");
            }
          }
        }
      );
    }
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  const handleFilter = (isDpiitRegistered) => {
    setValue("dpiitRegistered", isDpiitRegistered);
    if (!isDpiitRegistered) {
      setValue("dpiitRegNumber", "");
      clearErrors("dpiitRegNumber")
    }
  };
  const [valuePincode, setValuePincode] = useState('');
  const validatePincode = (value) => {
    if (value.length) {
      if (value.length !== 6) {
        return 'Pincode must be 6 digits';
      }
      const matches = value.match(
        /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
      );
      if (matches === null) {
        return "Only numbers allowed";
      }
    }
  };
  const [briefStartupDescriptionValue, setBriefStartupDescriptionValue] = useState('')
  const [uniqueDescValue, setUniqueDescValue] = useState('')


  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  const [isUploaded, setIsUploaded] = useState(false);
  const handleFileChange = (e) => {
    handleFileUpload(
      e,
      "udayamCertificate",
      "udayamCertificateName",
      setUdayamCertificateValue,
      setUdayamCertificateName,
      setUdayamCertificateURL,
      "file"
    );
    setIsUploaded(true);
  };


  const [isUploadedMoa, setIsUploadedMoa] = useState(false);
  const handleFileChangeMoa = (e) => {
    handleFileUpload(
      e,
      "moaDoc",
      "moaDocName",
      setMoaDocValue,
      setMoaDocName,
      setMoaDocURL,
      "file"
    )
    setIsUploadedMoa(true);
  }

  const [isUploadedCin, setIsUploadedCin] = useState(false);
  const handleFileChangeCin = (e) => {
    handleFileUpload(
      e,
      "inCorpCertificate",
      "inCorpCertificateName",
      setInCorporationValue,
      setInCorporationName,
      setInCorporationURL,
      "file"
    )
    setIsUploadedCin(true);
  }

  const [isUploadedDeed, setIsUploadedDeed] = useState(false);
  const handleFileChangeDeed = (e) => {
    handleFileUpload(
      e,
      "partnershipDeed",
      "partnershipDeedName",
      setPartnershipDeedValue,
      setPartnershipDeedName,
      setPartnershipDeedURL,
      "file"
    )
    setIsUploadedDeed(true);
  }


  const [isUploadedDpiit, setIsUploadedDpiit] = useState(false);
  const handleFileChangeDpiit = (e) => {
    handleFileUpload(
      e,
      "dpiitCertificate",
      "dpiitCertificateName",
      setDpiitCertificateValue,
      setDpiitCertificateName,
      setDpiitCertificateURL,
      "file"
    )
    setIsUploadedDpiit(true);
  }

  const [isUploadedLogo, setIsUploadedLogo] = useState(false);
  const handleFileChangeLogo = (e) => {
    handleFileUpload(
      e,
      "startupLogo",
      "startupLogoName",
      setLogoDisplayValue,
      setLogoDisplayName,
      setLogoDisplayUrl,
      "image"
    )
    setIsUploadedLogo(true);
  }

  const linkedInPattern = /^(https:\/\/(in|www)\.linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+)(\/)?$/;


  // const [dippTickIcon, setDippTickIcon] = useState(0);
  // const handleDippNumber = (e) => {
  //   var dippNumber = e.target.value
  //   if (dippNumber.length == 8 || dippNumber.length == 9 || dippNumber.length == 10) {
  //     var req = {
  //       dpiitNo: cryptoJS(dippNumber)
  //     }
  //     apiService('dpiit/validate', req, 'post').then((result) => {
  //       debugger
  //       if (result && result.data && result.data.response.responseStatus === 'Success') {
  //         setDippTickIcon(1)

  //       } else {
  //         setDippTickIcon(2)
  //       }

  //     }).catch((err) => {
  //       setDippTickIcon(2)
  //     });
  //   } else {
  //     setDippTickIcon(0)
  //   }
  // }


  return (
    <section className="container-fluid">
      <div className="form_div">
        <div className="d-flex align-items-center">
          <span className="back_span" onClick={handleBackapp}>
            {/* <i className="fas fa-arrow-alt-circle-left"></i> */}
            <img src='/images/Fig-back.png' className='img-fluid' alt='' />
          </span>{" "}
          <h6 className="ml-2 mb-0">Startup Details</h6>
        </div>
        <hr />
        <form
          className="signin-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="row">
            <div className="d-none">
              <div className="form-group">
                <Controller
                  name="id"
                  control={control}
                  defaultValue="0"
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="startupName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Startup Name is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Startup Name *"
                          placeholder="Enter Startup Name"
                          fullWidth
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startupName && errors.startupName.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="panNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'PAN Number is required',
                        validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN Number *"
                          placeholder="Enter PAN Number"
                          fullWidth
                          {...field}
                          type="text"
                          inputProps={{ readOnly: readyOnlyValue, maxLength: 10 }}
                          onKeyDown={(e) => checkAlphanumeric(e)}
                          onInput={(e) => {
                            handlePanValidate(e.target.value);
                          }}
                          error={Boolean(errors.pan && errors.pan)}
                          InputProps={{
                            endAdornment: panTickIcon === 1 ? (
                              <InputAdornment position="end">
                                <CheckCircleIcon color="success" />
                              </InputAdornment>
                            ) : panTickIcon === 2 ? (
                              <InputAdornment position="end">
                                <CloseIcon color="error" />
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.panNumber && errors.panNumber.message}
                    </FormHelperText>
                    {panTickIcon === 2 && (
                      <FormHelperText className="text-danger">
                        PAN Number Already Exists
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="entityType"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Legal Entity is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            // limitTags={2}
                            options={entityTypeList}
                            onChange={(e, selectedOptions) =>
                              handleSelectEntityType(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Legal Entity *" />
                            )}
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.entityType && errors.entityType.message}
                      </FormHelperText>

                    </FormControl>
                  </div>
                </div>

                {entityTypeValue === "LLP" && (
                  <div className="col-lg-4 col-12 p-0"></div>
                )}
                {entityTypeValue === 'Private Limited' && (
                  <div className="col-lg-6 col-12">
                    <div className="form-group mt-2">
                      <Controller
                        name="certificateNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "CIN Number is required",
                          validate: validateCin
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="CIN Number*"
                            placeholder="Enter CIN Number"
                            fullWidth
                            multiline
                            // inputProps={{ readOnly: readyOnlyValue }}
                            {...field}
                            type="text"
                            inputProps={{ readOnly: readyOnlyValue, maxLength: 21 }}
                            onKeyDown={(e) => checkAlphanumeric(e)}
                            onInput={(e) => {
                              handleCinValidate(e.target.value);
                            }}
                            error={Boolean(errors.cin && errors.cin)}
                            InputProps={{
                              endAdornment: cinTickIcon === 1 ? (
                                <InputAdornment position="end">
                                  <CheckCircleIcon color="success" />
                                </InputAdornment>
                              ) : (cinTickIcon === 2 || cinTickIcon === 3) ? (
                                <InputAdornment position="end">
                                  <CloseIcon color="error" />
                                </InputAdornment>
                              ) : null,
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.certificateNumber && errors.certificateNumber.message}
                      </FormHelperText>
                      {cinTickIcon === 2 && (
                        <FormHelperText className="text-danger">
                          CIN Number Already Exists
                        </FormHelperText>
                      )}
                      {cinTickIcon === 3 && (
                        <FormHelperText className="text-danger">
                          Invalid CIN Number
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                )}
                {entityTypeValue === "Other" && (
                  <div className="col-lg-6 col-12">
                    <div className="form-group mt-2">
                      <Controller
                        name="entityTypeOther"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Type of Entity is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Type of Entity *"
                            placeholder="Enter Type of Entity"
                            fullWidth
                            inputProps={{ readOnly: readyOnlyValue }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.entityTypeOther &&
                          errors.entityTypeOther.message}
                      </FormHelperText>
                    </div>
                  </div>
                )}
                {entityTypeValue === 'Proprietorship' && (
                  <div className="col-lg-4"></div>
                )}
                {entityTypeValue === 'Registered Partnership' && (
                  <div className="col-lg-4"></div>
                )}


                <div className="col-lg-6 col-12 mb-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <span className="mt-1">Are you DPIIT Registered?</span>
                      <span className="ml-5 d-flex justify-content-center align-items-center">
                        <Stack direction="row" spacing={1} alignItems="center">
                          No{" "}
                          <AntSwitch className="mx-2"
                            checked={dpiitRegistered}
                            disabled={readyOnlyValue}
                            onChange={() => handleFilter(!dpiitRegistered)}
                            inputProps={{ 'aria-label': 'ant design' }} />
                          Yes{" "}
                        </Stack>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="dpiitRegNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: dpiitRegistered && "DPIIT Registered No is required",
                        validate: dpiitRegistered ? validateDPIINumber : ''
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label={dpiitRegistered ? `DPIIT Registered No *` : 'DPIIT Registered No'}
                          placeholder="Enter DPIIT Registered No"
                          fullWidth
                          multiline
                          inputProps={{ disabled: !dpiitRegistered || readyOnlyValue }}
                          // disabled={!dpiitRegistered || readyOnlyValue}
                          // onInput={(e) => {
                          //   handleDippNumber(e);
                          // }}
                          onKeyDown={(e) => checkAlphanumeric(e)}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.dpiitRegNumber && errors.dpiitRegNumber.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Address is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Address *"
                          placeholder="Enter Your Startup registered address"
                          fullWidth
                          multiline
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.address && errors.address.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            // limitTags={2}
                            // value={stateName}
                            options={stateList}
                            onChange={(e, selectedOptions) => handleSelectStateType(selectedOptions)}
                            renderInput={(params) => (
                              <TextField {...params} label="State *" />
                            )}
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.state && errors.state.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                {getValues('state') === "Tamil Nadu" ? (
                  <>
                    <div className="col-lg-6 col-12">
                      <div className="form-group mt-2">
                        <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            rules={{ required: "District is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                disableClearable
                                disablePortal
                                {...field}
                                // limitTags={2}
                                options={cityList}
                                onChange={(e, selectedOptions) =>
                                  handleSelectCity(selectedOptions)
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label="District *" />
                                )}
                                disabled={readyOnlyValue}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.city && errors.city.message}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 col-12">
                      <div className="form-group mt-2">
                        <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            rules={{ required: "District is required" }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="outlined"
                                label="District *"
                                placeholder="Enter District"
                                fullWidth
                                inputProps={{ readOnly: readyOnlyValue }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.city && errors.city.message}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
                {/* <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="country"
                      control={control}
                      defaultValue="India"
                      rules={{ required: "Country is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Country *"
                          placeholder="Enter Country"
                          fullWidth
                          multiline
                          inputProps={
                            { readOnly: readyOnlyValue, }
                          }
                          {...field}
                        // disabled
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.country && errors.country.message}
                    </FormHelperText>
                  </div>
                </div> */}
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="pincode"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Pincode is required", validate: validatePincode }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Pincode *"
                          placeholder="Enter Pincode"
                          fullWidth
                          multiline
                          inputProps={{ readOnly: readyOnlyValue, maxLength: 6 }}
                          {...field}
                          value={valuePincode}
                          onInput={(e) => {
                            const { value } = e.target;
                            if (value === "" || (/^\d{1,6}$/.test(value))) {
                              setValuePincode(value);
                            }
                          }}

                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pincode && errors.pincode.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="website"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Company Website is required",
                        // validate: {
                        //   noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        // },
                        pattern: {
                          value: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/,
                          message: "Please enter a valid URL Whitespace not allowed"
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Company Website*"
                          placeholder="Enter Company Website"
                          fullWidth
                          multiline
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.website && errors.website.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="linkedIn"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: false,
                        pattern: {
                          value: linkedInPattern,
                          message: 'Invalid LinkedIn profile URL'
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Company LinkedIn Profile"
                          placeholder="Enter Company LinkedIn Profile "
                          fullWidth
                          multiline
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.linkedIn && errors.linkedIn.message}
                    </FormHelperText>
                  </div>
                </div>



                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="briefStartupDescription"
                      control={control}
                      defaultValue=""
                      rules={{ required: "About Company is required" }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          defaultValue="Default Value"
                          variant="outlined"
                          label="About Company *"
                          placeholder="Describe about your company, products, Services in 300 words"
                          fullWidth
                          inputProps={{ readOnly: readyOnlyValue, maxLength: 300 }}
                          {...field}
                          value={briefStartupDescriptionValue}
                          // onChange={handleChangeBriefStartupDescription}
                          onChange={(e) => {
                            const { value } = e.target;
                            setBriefStartupDescriptionValue(value);
                            setValue('briefStartupDescription', value)
                            if (value !== '') {
                              clearErrors('briefStartupDescription')
                            } else {
                              setError('briefStartupDescription', { message: 'About Company is required' })
                            }

                            // setError(value.length > 150);
                          }}
                        // error={error}
                        // helperText={error ? "Input must be at least 150 characters" : ""}
                        />
                      )}
                    />
                    <FormHelperText className="text-right">{briefStartupDescriptionValue.length} / 300</FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.briefStartupDescription &&
                        errors.briefStartupDescription.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="uniqueDesc"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          defaultValue="Default Value"
                          variant="outlined"
                          label="How Unique You Are *"
                          placeholder="Describe How Unique You Are"
                          fullWidth
                          inputProps={{ readOnly: readyOnlyValue, maxLength: 300 }}
                          {...field}
                          value={uniqueDescValue}
                          onChange={(e) => {
                            const { value } = e.target;
                            setUniqueDescValue(value);
                            setValue('uniqueDesc', value)
                            if (value !== '') {
                              clearErrors('uniqueDesc')
                            } else {
                              setError('uniqueDesc', { message: 'Required' })
                            }

                            // setError(value.length > 150);
                          }}
                        // error={error}
                        // helperText={error ? "Input must be at least 150 characters" : ""}
                        />
                      )}
                    />
                    <FormHelperText className="text-right">{uniqueDescValue.length} / 300</FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.uniqueDesc &&
                        errors.uniqueDesc.message}
                    </FormHelperText>
                  </div>
                </div>
                {
                  showAddInfo === "true" && (
                    <div className='col-lg-6 col-12'>
                      <div className="form-group mt-2">
                        <FormControl sx={{ width: '100%' }}>
                          {/* <InputLabel htmlFor="grouped-native-select">Current Growth Stage of Your Startup *</InputLabel> */}
                          <Controller name="currentgrowthStage" control={control} defaultValue="" rules={{ required: 'Growth Stage of Your Startup is required' }}
                            render={({ field }) =>
                              <Autocomplete
                                disableClearable
                                disablePortal
                                {...field}
                                limitTags={2}
                                options={startupStageData}
                                onChange={(e, selectedOptions) => handleSelectStartupStage(selectedOptions)}
                                renderInput={(params) => <TextField {...params} label="Current Growth Stage of Your Startup *" />}
                                disabled={readyOnlyBDDValue}
                              />
                            } />
                          <FormHelperText className='text-danger'>{errors.currentgrowthStage && errors.currentgrowthStage.message}</FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  )
                }
                {/* <div className="col-lg-12 col-12">
                  {dpiitRegistered === true && (
                    <>
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="form-group mt-2">
                            <Controller
                              name="dpiitRegNumber"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "DPIIT Registered No is required",
                              }}
                              render={({ field }) => (
                                <TextField
                                  variant="outlined"
                                  label="DPIIT Registered No *"
                                  placeholder="Enter DPIIT Registered No"
                                  fullWidth
                                  multiline
                                  inputProps={{ readOnly: readyOnlyValue }}
                                  {...field}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.dpiitRegNumber &&
                                errors.dpiitRegNumber.message}
                            </FormHelperText>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          {certificateFile === "save" ? (
                            <div className="form-group mt-2">
                              <Controller
                                name="dpiitCertificate"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required:
                                    "DPIIT Registration Certificate File is required",
                                }}
                                render={({ field }) => (
                                  <MuiFileInput
                                    {...field}
                                    onChange={(e) =>
                                      handleFileUpload(
                                        e,
                                        "dpiitCertificate",
                                        "dpiitCertificateName",
                                        setDpiitCertificateValue,
                                        setDpiitCertificateName,
                                        setDpiitCertificateURL,
                                        "file"
                                      )
                                    }
                                    value={dpiitCertificateValue}
                                    variant="outlined"
                                    // inputProps={}
                                    // onReset={(e) => handleremove(e)}
                                    // inputRef={inputRef}
                                    // inputProps= {
                                    //   ref=
                                    // }

                                    fullWidth
                                    label="Upload DPIIT Registration Certificate *"
                                    placeholder="Choose File"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.dpiitCertificate &&
                                  errors.dpiitCertificate.message}
                              </FormHelperText>
                              <div>
                                <span className="fn-12">
                                  Maximum 5 mb allowed doc (png, jpg, jpeg,
                                  pdf) <span className="text-danger">*</span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p className="mb-0">
                                DPIIT Registration Certificate File
                              </p>
                              <div className="row">
                                <div className="col-lg-7 col-12">
                                  <Controller
                                    name="dpiitCertificate"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required:
                                        "DPIIT Registration Certificate File is required",
                                    }}
                                    render={({ field }) => (
                                      <Button
                                        component="label"
                                        variant="contained"
                                        {...field}
                                        fullWidth
                                        onChange={(e) =>
                                          handleFileUpload(
                                            e,
                                            "dpiitCertificate",
                                            "dpiitCertificateName",
                                            setDpiitCertificateValue,
                                            setDpiitCertificateName,
                                            setDpiitCertificateURL,
                                            "file"
                                          )
                                        }
                                        startIcon={<CloudUploadIcon />}
                                        href="#file-upload"
                                      >
                                        Change
                                        {readyOnlyValue === false ? (
                                          <VisuallyHiddenInput type="file" />
                                        ) : (
                                          <></>
                                        )}
                                      </Button>
                                    )}
                                  />
                                  <div>
                                    <span className="fn-12">
                                      Maximum 5 mb allowed doc (png, jpg, jpeg,
                                      pdf){" "}
                                      <span className="text-danger">*</span>
                                    </span>
                                    <FormHelperText className="text-danger">
                                      {errors.dpiitCertificate &&
                                        errors.dpiitCertificate.message}
                                    </FormHelperText>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-12">
                                  <Fab variant="extended">
                                    <Link
                                      to={`${dpiitCertificateURL}`}
                                      target="_blank"
                                      color="black"
                                    >
                                      <PreviewIcon sx={{ mr: 1 }} />
                                      View Document
                                    </Link>
                                  </Fab>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div> */}
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-12'>
              <div className='form-group my-1'>
                {/* <FormLabel shrink="true" className='input_Lable'>Udayam Certificate<span className='text-danger'>*</span> </FormLabel> */}
                {/* <Controller name="startupLogo" control={control} defaultValue="" rules={{ required: false }}
                  render={({ field }) =>
                    <FileUploader children={
                      <div className="fileupload-img-bg" >
                        <center>
                          <p className="fileupload-img">
                            {readyOnlyValue === false ? (
                              <>
                                <img
                                  src={logoDisplayUrl}
                                  className="user_logo-scst-link"
                                  alt={logoDisplayName}
                                />
                              </>
                            ) : (
                              <>
                                {
                                  logoDisplayUrl ? (
                                    <img src={logoDisplayUrl} className="user_logo-scst-link mr-2 img-fluid" />
                                  ) : (
                                    <span> <img src="/images/upload-img.png" className="user_logo-scst mr-2 img-fluid" alt="" /> Upload Brand Logo </span>
                                  )
                                }
                              </>
                            )}
                          </p>
                        </center>
                      </div>}
                      {...field} handleChange={(e) => handleFileUpload(e, "startupLogo", "startupLogoName", "logoDisplayUrl")} name="file" types={fileTypesimg} />
                  } /> */}
                <Controller
                  name="startupLogo"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={() => (
                    <div className="fileupload-img-bg">
                      <center>
                        <p className="fileupload-img">
                          {readyOnlyValue === false ? (
                            <img
                              src={logoDisplayUrl}
                              className="user_logo-scst-link"
                              alt={logoDisplayName}
                            />
                          ) : (
                            <>
                              {logoDisplayUrl ? (
                                <img src={logoDisplayUrl} className="user_logo-scst-link mr-2 img-fluid" />
                              ) : (
                                <span>
                                  <img src="/images/upload-img.png" className="user_logo-scst mr-2 img-fluid" alt="" />
                                  Upload Brand Logo
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </center>
                    </div>
                  )}
                />
                {/* <FormHelperText>File format should be in .pdf</FormHelperText> */}
                {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                {/* <FormHelperText className='text-danger'>{errors.startupLogo && errors.startupLogo.message}</FormHelperText> */}
              </div>
              <div className="col-lg-4 col-12 p-0">
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="startupLogo" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleFileChangeLogo}
                          value={getValues('logoDisplayUrl')}
                          name="file"
                          types={fileTypesimg}
                          children={
                            <div className={isUploadedLogo ? 'uploadlogo-active' : 'uploadlogo'}>
                              {/* "fileupload-pdf-dpiit-bg" */}
                              <center>
                                <p className={isUploadedLogo ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon " />
                                    {isUploadedLogo ? 'Uploaded (Click to Change) ' : 'Upload Startup Logo'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in png, jpg</FormHelperText>
                    <FormHelperText className='text-danger'>{errors.startupLogo && errors.startupLogo.message}</FormHelperText>
                  </div>
                </div>
              </div>
              {entityTypeValue === "Private Limited" && (
                <>
                  <div className="col-lg-4 col-12 p-0">
                    {
                      getValues('moaDoc') !== '' && (<span>MOA Certificate <span className="text-danger">*</span></span>)
                    }
                    <div className="d-flex align-items-center">
                      <div className='form-group my-1'>
                        <Controller name="moaDoc" control={control} defaultValue="" rules={{ required: 'MOA & AOA Certificate is required' }}
                          render={({ field }) =>
                            <FileUploader
                              {...field}
                              handleChange={handleFileChangeMoa}
                              value={moaDocValue}
                              name="file"
                              types={fileTypes}
                              children={
                                <div className={getValues('moaDoc') !== '' ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                  <center>
                                    <p className={getValues('moaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                      <span>
                                        <AttachFileIcon className="file-icon " />
                                        {getValues('moaDoc') !== '' ? 'Uploaded (Click to Change) ' : 'MOA Certificate *'}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          } />
                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .pdf</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.moaDoc && errors.moaDoc.message}</FormHelperText>
                      </div>
                      <span className="pre-btn" onClick={() => handleClickOpen(moaDocURL)}> <VisibilityIcon className='icon-size mr-1' />
                        <Link
                          // to={`${moaDocURL}`}
                          // target="_blank"

                          style={{ color: "#80809e" }}
                        >Preview
                        </Link>
                      </span>
                    </div>                    
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 p-0">
                    {
                      getValues('inCorpCertificate')!=='' && (<span>CIN Certificate <span className="text-danger">*</span></span>)
                    }
                    <div className="d-flex align-items-center">
                      <div className='form-group my-1'>
                        <Controller name="inCorpCertificate" control={control} defaultValue="" rules={{ required: 'CIN Registration Certificate File is required' }}
                          render={({ field }) =>
                            <FileUploader
                              {...field}
                              handleChange={handleFileChangeCin}
                              value={inCorporationValue}
                              name="file"
                              types={fileTypes}
                              children={
                                <div className={getValues('inCorpCertificate')!=='' ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                  {/* "fileupload-pdf-dpiit-bg" */}
                                  <center>
                                    <p className={getValues('inCorpCertificate')!=='' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                      <span>
                                        <AttachFileIcon className="file-icon" />
                                        {getValues('inCorpCertificate')!=='' ? 'Uploaded (Click to Change) ' : 'CIN Certificate *'}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          } />
                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .pdf</FormHelperText>
                        {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                        <FormHelperText className='text-danger'>{errors.inCorpCertificate && errors.inCorpCertificate.message}</FormHelperText>
                      </div>
                      <span className="pre-btn" onClick={() => handleClickOpen(inCorporationURL)}> <VisibilityIcon className='icon-size mr-1' />
                        <Link
                          // to={`${inCorporationURL}`}

                          // target="_blank"
                          style={{ color: "#80809e" }}
                        >Preview
                        </Link>
                      </span>
                      {/* <span className="pre-btn"> <DeleteIcon className='icon-size' /></span> */}
                    </div>                    
                  </div>
                </>
              )}
              {entityTypeValue === "Proprietorship" && (
                <>
                  {
                    getValues('udayamCertificate')!=='' && (<span>Udyam Certificate <span className="text-danger">*</span></span>)
                  }
                  <div className='col-lg-4 col-md-4 col-12 p-0'>
                    <div className="d-flex align-items-center">
                      <div className='form-group my-1'>
                        <Controller name="udayamCertificate" control={control} defaultValue="" rules={{ required: 'Udyam Certificate is required' }}
                          render={({ field }) =>
                            <FileUploader
                              {...field}
                              handleChange={handleFileChange}
                              value={udayamCertificateValue}
                              name="file"
                              types={fileTypes}
                              children={
                                <div className={getValues('udayamCertificate')!=='' ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                  <center>
                                    <p className={getValues('udayamCertificate')!=='' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                      <span>
                                        <AttachFileIcon className="file-icon" />
                                        {getValues('udayamCertificate')!=='' ? 'Uploaded (Click to Change) ' : 'Udyam Certificate *'}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          } />
                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .pdf</FormHelperText>
                        {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                        <FormHelperText className='text-danger'>{errors.udayamCertificate && errors.udayamCertificate.message}</FormHelperText>
                      </div>
                      <span className="pre-btn" onClick={() => handleClickOpen(udayamCertificateURL)}> <VisibilityIcon className='icon-size mr-1' />
                        <Link
                          // to={`${udayamCertificateURL}`}
                          // target="_blank"

                          style={{ color: "#80809e" }}
                        >Preview
                        </Link>
                      </span>
                      {/* <span className="pre-btn"> <DeleteIcon className='icon-size' /></span> */}
                    </div>
                  </div>                 

                </>
              )}
              {entityTypeValue === "Registered Partnership" && (
                <>
                  {
                    getValues('partnershipDeed')!=='' && (<span>Partnership Deed Certificate <span className="text-danger">*</span></span>)
                  }
                  <div className="col-lg-4 col-12 p-0">
                    <div className="d-flex align-items-center">
                      <div className='form-group my-1'>
                        <Controller name="partnershipDeed" control={control} defaultValue="" rules={{ required: 'Partnership Deed is required' }}
                          render={({ field }) =>
                            <FileUploader
                              {...field}
                              handleChange={handleFileChangeDeed}
                              value={partnershipDeedURL}
                              name="file"
                              types={fileTypes}
                              children={
                                <div className={getValues('partnershipDeed')!=='' ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                  {/* "fileupload-pdf-dpiit-bg" */}
                                  <center>
                                    <p className={getValues('partnershipDeed')!=='' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                      <span>
                                        <AttachFileIcon className="file-icon " />
                                        {getValues('partnershipDeed')!=='' ? 'Uploaded (Click to Change) ' : 'Partnership Deed Certificate *'}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          } />
                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .pdf</FormHelperText>
                        {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                        <FormHelperText className='text-danger'>{errors.partnershipDeed && errors.partnershipDeed.message}</FormHelperText>
                      </div>
                      <span className="pre-btn" onClick={() => handleClickOpen(partnershipDeedURL)}> <VisibilityIcon className='icon-size mr-1' />
                        <Link style={{ color: "#80809e" }}>Preview </Link>
                      </span>
                    </div>                    
                  </div>
                </>
              )}
              {dpiitRegistered === true && (
                <>
                  {
                    getValues('dpiitCertificate')!=='' && (<span>DPIIT Registered Certificate <span className="text-danger">*</span></span>)
                  }
                  <div className="col-lg-6 col-12 p-0">
                    <div className="d-flex align-items-center">
                      <div className='form-group my-1'>
                        <Controller name="dpiitCertificate" control={control} defaultValue="" rules={{ required: 'DPIIT Registered Certificate is required' }}
                          render={({ field }) =>
                            <FileUploader
                              {...field}
                              handleChange={handleFileChangeDpiit}
                              value={dpiitCertificateValue}
                              name="file"
                              types={fileTypes}
                              children={
                                <div className={getValues('dpiitCertificate')!=='' ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                  <center>
                                    <p className={getValues('dpiitCertificate')!=='' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                      <span>
                                        <AttachFileIcon className="file-icon" />
                                        {getValues('dpiitCertificate')!=='' ? 'Uploaded (Click to Change) ' : 'DPIIT Registered Certificate *'}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          } />
                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .pdf</FormHelperText>
                        <FormHelperText className='text-danger'>{errors.dpiitCertificate && errors.dpiitCertificate.message}</FormHelperText>
                      </div>
                      <span className="pre-btn" onClick={() => handleClickOpen(dpiitCertificateURL)}>
                        <VisibilityIcon className='icon-size mr-1' />
                        <Link style={{ color: "#80809e" }}> Preview</Link>
                      </span>
                    </div>                    
                  </div>
                </>
              )}
            </div>



            {/* <div className="col-lg-4 col-12 text-center mb-3">
              <div className="logo_div">
                <img
                  src={logoDisplayUrl}
                  className="user_logo"
                  alt={logoDisplayName}
                />
                {readyOnlyValue === false ? (
                  <div className="camera_icon">
                    <Button
                      onChange={(e) =>
                        handleFileUpload(
                          e,
                          "startupLogo",
                          "startupLogoName",
                          setLogoDisplayValue,
                          setLogoDisplayName,
                          setLogoDisplayUrl,
                          "image"
                        )
                      }
                      component="label"
                      startIcon={<CameraAltIcon />}
                    >
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <span>
                  Startup Logo
                </span>
              </div>
              <div>
                <span className="fn-12">Recommend Size (128px * 128px)</span>
              </div>
              <div>
                <span className="fn-12">
                  Maximum 5 mb allowed doc (png, jpg, jpeg){" "}
                  <span className="text-danger">*</span>
                </span>
              </div>
              {logoInvalid === true ? (
                <span className="text-danger">Startup Logo is required</span>
              ) : (
                <></>
              )}
            </div> */}
          </div>

          {/* <div className='col-lg-8 col-12 mt-3 p-0'>
                        {
                            (checkMethod === 'save') ? (
                                <div className="form-group mt-2">
                                    <Controller name="dpiitCertificate" control={control} defaultValue="" rules={{ required: 'DPIIT Registration Certificate File is required' }}
                                        render={({ field }) =>
                                            <MuiFileInput
                                                {...field}
                                                onChange={(e) => handleFileUpload(e, "dpiitCertificate", "dpiitCertificateName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                value={inCorporationValue}
                                                variant='outlined'
                                                // inputProps={}
                                                // onReset={(e) => handleremove(e)}
                                                inputRef={inputRef}
                                                // inputProps= {
                                                //   ref=
                                                // }

                                                fullWidth
                                                label="Upload DPIIT Registration Certificate *"
                                                placeholder='Choose File' />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.dpiitCertificate && errors.dpiitCertificate.message}</FormHelperText>
                                    <div>
                                        <span className='fn-12'>Maximum 5 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className='mb-0'>DPIIT Registration Certificate File</p>
                                    <div className='row'>
                                        <div className='col-lg-8 col-12'>
                                            <Controller name="dpiitCertificate" control={control} defaultValue="" rules={{ required: 'DPIIT Registration Certificate File is required' }}
                                                render={({ field }) =>
                                                    <Button
                                                        component="label"
                                                        variant="contained"
                                                        {...field}
                                                        fullWidth
                                                        onChange={(e) => handleFileUpload(e, "dpiitCertificate", "dpiitCertificateName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                        startIcon={<CloudUploadIcon />}
                                                        href="#file-upload"
                                                    >
                                                        Change
                                                        {
                                                            (readyOnlyValue === false) ? (
                                                                <VisuallyHiddenInput type="file" />
                                                            ) : (<></>)
                                                        }
                                                    </Button>

                                                } />
                                            <div>
                                                <span className='fn-12'>Maximum 5 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-12'>
                                            <Fab variant="extended">
                                                <Link to={`${inCorporationURL}`} target="_blank" color='black'>
                                                    <PreviewIcon sx={{ mr: 1 }} />
                                                    View Document
                                                </Link>
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div> */}


          {/* <div className="row">
          </div> */}

          <div className="row"></div>

          <div className="form-group d-flex justify-content-end mt-4 ">
            <Button
              variant="contained"
              onClick={handleBackapp}
              className="btn btn-cancel submit px-3"
            >
              Cancel
            </Button>
            {readyOnlyBDDValue === false ? (
              <Button
                type="submit"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3 ml-3"
              >
                <span>Save & Proceed Next</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div >
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </section >
  );
};

export default Applicationscst;
